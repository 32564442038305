import { Grid, Grow, Link } from "@mui/material"

function Links() {
    return (
      <Grid sx={{mx: "auto", width: "fit-content"}} container spacing={2}>
      <Grid item xs={4}>
        <Grow in={true} timeout={1500}>
          <Link href="mailto:work@joeharrel.com" underline="none">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill="#eee" width="24" height="24"
              viewBox="0 0 330.001 330.001">
              <path d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602
                L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z"
                />
              <polygon points="165.001,146.4 310.087,40.001 19.911,40 	"/>
            </svg>
          </Link>
        </Grow>
      </Grid>
      <Grid item xs={4}>
        <Grow in={true} timeout={1500}>
          <Link href="https://www.github.com/joeharrel" underline="none">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill="#eee" width="24" height="24"
              viewBox="0 0 50 50">
              <path d="M25.26,4C13.52,4,4,13.52,4,25.26c0,9.39,6.09,17.36,14.54,20.17c1.06,0.19,1.45-0.46,1.45-1.02
                c0-0.5-0.02-1.84-0.03-3.62c-5.91,1.28-7.16-2.85-7.16-2.85c-0.97-2.46-2.36-3.11-2.36-3.11c-1.93-1.32,0.15-1.29,0.15-1.29
                c2.13,0.15,3.26,2.19,3.26,2.19c1.9,3.25,4.98,2.31,6.19,1.77c0.19-1.37,0.74-2.31,1.35-2.84c-4.72-0.54-9.68-2.36-9.68-10.51
                c0-2.32,0.83-4.22,2.19-5.7c-0.22-0.54-0.95-2.7,0.21-5.63c0,0,1.78-0.57,5.85,2.18c1.7-0.47,3.51-0.71,5.32-0.72
                c1.81,0.01,3.62,0.24,5.32,0.72c4.06-2.75,5.84-2.18,5.84-2.18c1.16,2.93,0.43,5.09,0.21,5.63c1.36,1.49,2.19,3.38,2.19,5.7
                c0,8.17-4.97,9.96-9.71,10.49c0.76,0.66,1.44,1.95,1.44,3.94c0,2.84-0.03,5.13-0.03,5.83c0,0.57,0.38,1.23,1.46,1.02
                c8.44-2.82,14.52-10.78,14.52-20.17C46.51,13.52,37,4,25.26,4z"/>
            </svg>
          </Link>
        </Grow>
      </Grid>
      <Grid item xs={4}>
        <Grow in={true} timeout={1500}>
          <Link href="https://www.linkedin.com/in/joeharrel" underline="none">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"x="0px" y="0px" width="24" height="24"
              viewBox="0 0 24 24">
            <rect fill="#FFFFFF" x="3.9" y="4.3" width="16.1" height="15.4"/>
            <path fill="#2665C0" d="M20.5,2h-17C2.7,2,2,2.7,2,3.5v17C2,21.3,2.7,22,3.5,22h17c0.8,0,1.5-0.7,1.5-1.5v-17C22,2.7,21.3,2,20.5,2z
              M8,19H5v-9h3V19z M6.5,8.2c-1,0-1.7-0.8-1.7-1.8c0-1,0.8-1.7,1.8-1.7c0.9,0,1.7,0.8,1.7,1.7C8.3,7.5,7.5,8.3,6.5,8.2z M19,19h-3
              v-4.7c0-1.4-0.6-1.9-1.4-1.9c-1,0.1-1.7,0.9-1.6,1.9c0,0,0,0,0,0c0,0,0,0.1,0,0.1V19h-3v-9h2.9v1.3c0.6-0.9,1.6-1.4,2.7-1.4
              c1.6,0,3.4,0.9,3.4,3.7L19,19z"/>
            </svg>
          </Link>
        </Grow>
      </Grid>
    </Grid>
    );
}

export default Links;