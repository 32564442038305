import { Typography } from '@mui/material';

function Greeting () {
  return (
    <>
      <Typography variant="h3">Hi There</Typography>
      <Typography variant="h4">I'm Joe :)</Typography>
    </>
  );
}

export default Greeting;