// import React from 'react';
// import logo from './logo.svg';
// import './App.css';


import * as React from 'react';
import Greeting from './components/Greeting'
import Links from './components/Links'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid, Box } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{textAlign: "center", mx: "auto", width: "80%", height: "100vh"}}>
        <Grid container spacing={2} sx={{position: "relative", top: "100px"}}>
          <Grid item xs={12}>
            <Greeting/>
          </Grid>
          <Grid item xs={12}>
            <Links/>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default App;
